<template>
  <svg
    width="16"
    height="21"
    viewBox="0 0 16 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.61914 4.61863C4.61914 4.61863 5.0216 4.28973 5.29286 4.00833C5.41185 3.8849 5.45783 3.82869 5.5239 3.71387"
      stroke="#FAE9EC"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.33291 5.63405L6.12657 6.42866L5.31768 5.36655L3.71387 4.61914"
      stroke="#FAE9EC"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.0473 17.2856H8.69015L8.46102 16.5882L7.33301 16.3809"
      stroke="#FAE9EC"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.66185 1H1V2.04926L1.61524 2.51539C4.32992 4.84793 4.82333 8.81098 4.82333 12.0739V20H10.7461V12.0739C10.7461 8.81098 11.2395 4.84793 13.9542 2.51539L14.5714 2.04926V1H7.66185Z"
      stroke="#FAE9EC"
      stroke-miterlimit="10"
    />
    <path
      d="M11.8574 1L11.5748 2.04715L10.651 2.20635L10.0479 2.80952"
      stroke="#FAE9EC"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {}
</script>

<style></style>
